* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Open sans", sans-serif;
  background-color: rgb(3, 7, 26);
  color: #fff;
}

.page {
  width: 100%;
  min-height: calc(100vh - 180px);
  display: flex;
  flex-direction: column;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: transparent;
  padding: 20px 0;
  text-align: center;
}
