/*HEADER GLOBAL STYLE*/

header a {
  text-decoration: none;
  color: lightblue;
  font-size: 18px;
  transition: all 0.2 ease;
}

header a:hover {
  color: rgb(3, 233, 244);
  cursor: pointer;
}

/* HEADER */

header {
  position: relative;
  padding: 0 2rem;
  background-color: transparent;
  backdrop-filter: blur(10);
}

.navbar {
  width: 100%;
  height: 70px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  margin-top: 1rem;
  -webkit-transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
}

.logo:hover {
  -webkit-transform: scale(1.1) rotate(0.01deg);
  transform: scale(1.1) rotate(0.01deg);
}

.menuLinks {
  display: flex;
  gap: 2rem;
  letter-spacing: 0.05rem;
  font-family: "Share Tech Mono", monospace;
}

.menuLink a {
  color: transparent;
}

.activeLink {
  border-top: 2px solid rgb(138, 43, 226) !important;
  padding-top: 0.1rem;
  transition: border-top-color 1.2s ease-in;
}

.menuBtn {
  color: rgb(3, 233, 244);
  cursor: pointer;
  display: none;
}

.menuBtn:hover {
  scale: 1.1;
}

.buttonLinks {
  font-size: 16px;
  color: rgb(69, 187, 226);
  font-family: inherit;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  border: none;
  background: none;
  text-transform: uppercase;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: color;
}

.buttonLinks:hover {
  color: rgb(3, 233, 244);
}


.buttonLinks:hover:after {
  width: 100%;
  left: 0%;
}

.buttonLinks:after {
  content: "";
  pointer-events: none;
  bottom: -10px;
  left: 50%;
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: rgb(138, 43, 226);
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: width, left;
}

/*RESPONSIVE*/

@media screen and (max-width: 992px) {
  header {
    background-color: transparent;
    backdrop-filter: none;
  }
  .menuLinks {
    display: none;
  }

  .menuBtn {
    display: block;
  }
}
