.sidebar {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 300px;
    padding: 3rem 0;
    background-color: rgb(4, 9, 32, 0.3);
    backdrop-filter: blur(10px);
    overflow: hidden;
    transform: translateX(-100%);
    transition: transform .4s ease-in-out;
    z-index: 999;
    font-family: "Share Tech Mono", monospace;
    
}

.sidebar.open {
    transform: translateY(0);
}

.closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: rgb(3, 233, 244);
    cursor: pointer;
}

.closeIcon:hover {
    opacity: 0.9;
    scale: 1.1;
}

.sidebar a {
    padding: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.activeLink {
    background-color: rgba(137, 43, 226, 0.3) !important;
    color:rgb(3, 233, 244);
}

/*RESPONSIVO*/

@media screen and (max-width: 992px) {
    .sidebar {
        
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}