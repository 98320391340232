.backgroundContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 120px);
  object-fit: cover;
  opacity: 0.05;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(3, 7, 26, 0.5);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  box-sizing: border-box;
  position: relative;
}

.pageTitle {
  margin-top: 4rem;
  margin-bottom: 2rem;
  display: flex;
  align-self: flex-start;
}

.pageTitle span {
  color: rgb(3, 233, 244);
  font-size: 2.2rem;
  font-family: "Share Tech Mono", monospace;
}

.cardContainer {
  display: flex;
  align-items: center;
  max-width: 800px;
  height: 250px;
  border: 2px solid rgb(138, 43, 226, 0.3);
  border-radius: 0.5rem;
  margin: 1rem auto;
  background-color: rgba(3, 233, 244, 0.1);
}

.cardContainerDescrption {
  display: block;
}

.cardTitle {
  margin: auto 0.5rem;
}
.cardTitle span {
  font-size: 2.3rem;
  font-weight: 600;
  font-family: "Share Tech Mono", monospace;
  color: rgb(138, 43, 226);
}

.cardDescription {
  font-size: 1rem;
  font-family: "Share Tech Mono", monospace;
  margin: 0 0.5rem;
  color: rgb(178, 224, 224);
}

.cardSkills {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: start;
  gap: 1rem;
  align-items: center;
  margin: 1rem 0.5rem;
}

.cardSkills span {
  font-size: 0.85rem;
  font-family: "Share Tech Mono", monospace;
  border-radius: 0.3rem;
  padding: 0.2rem;
  background-color: rgb(138, 43, 226, 0.5);
}

.imgCard img {
  margin: 0.5rem;
  max-width: 400px;
  border: 2px solid #00a1ff;
  border-radius: 0.5rem;
  -webkit-transition: -webkit-transform 0.7s;
  transition: transform 0.7s;
  cursor: pointer;
}

.imgCard img:hover {
  -webkit-transform: scale(1.1) rotate(0.01deg);
  transform: scale(1.1) rotate(0.01deg);
}

.btnProjectLink {
  text-decoration: none;
}

.btnProject {
  align-items: center;
  background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-family: "Share Tech Mono", monospace;
  font-size: 18px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 3px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
  margin-top: 1rem;
  margin-bottom: 5rem;
}

.btnProject:active,
.btnProject:hover {
  outline: 0;
}

.btnProject span {
  background-color: rgb(5, 6, 45);
  padding: 8px 24px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.btnProject:hover span {
  background: none;
}

.btnProject:active {
  transform: scale(0.9);
}

/* RESPONSIVO */

@media screen and (max-width: 992px) {
  .cardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 420px;
    height: 450px;
    border: 2px solid rgb(138, 43, 226, 0.3);
    border-radius: 0.5rem;
    margin: 2rem auto;
    background-color: rgba(3, 233, 244, 0.1);
  }
}
@media screen and (max-width: 830px) {
  .imgCard img:hover {
    -webkit-transform: scale(1.1) rotate(0.01deg);
    transform: scale(1.1) rotate(0.01deg);
  }
}
@media screen and (max-width: 640px) {
  .imgCard img:hover {
    -webkit-transform: scale(1.1) rotate(0.01deg);
    transform: scale(1.1) rotate(0.01deg);
  }
}
@media screen and (max-width: 520px) {
  .cardTitle span {
    font-size: 1.5rem;
    font-weight: 600;
    color: rgb(138, 43, 226);
    margin-bottom: 0rem;
  }

  .cardTitle {
    margin-bottom: 0rem;
  }

  .cardContainer {
    max-width: 320px;
    height: 100%;
  }
  .imgCard img {
    max-width: 300px;
  }

  .imgCard img:hover {
    -webkit-transform: scale(1.1) rotate(0.01deg);
    transform: scale(1.1) rotate(0.01deg);
  }
}
