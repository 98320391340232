
.backgroundContainer {
  position: relative;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 80px);
  object-fit: cover;
  opacity: 0.05;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(3, 7, 26, 0.5);
}


.contactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  position: relative;
}

.contactTitle {
  margin-top: 4rem;
  margin-bottom: 3rem;
  display: flex;
  align-self: baseline;
  
}

.contactTitle span {
  color: rgb(3, 233, 244);
  font-size: 2.2rem;
  font-family: "Share Tech Mono", monospace;
 
}

.formContact {
  display: flex;
  flex-direction: column;
}

.inputContact {
  width: 40rem;
  padding: 10px;
  color: rgb(178, 224, 224);
  background-color: transparent;
  border: 2px solid rgb(138, 43, 226);
  border-radius: 5px;
  font-size: 1.1rem;
  font-family: "Share Tech Mono", monospace;
  margin-bottom: 0.5rem;

  &::placeholder {
    color: #fff;
  }
  @media screen and (max-width: 756px) {
    width: 300px;
  }
}

.textareaContact {
  width: 40rem;
  height: 12rem;
  padding: 10px;
  color: rgb(178, 224, 224);
  background-color: transparent;
  border: 2px solid rgb(138, 43, 226);
  border-radius: 5px;
  display: flex;
  font-family: "Share Tech Mono", monospace;
  font-size: 1.1rem;
  margin-bottom: 2rem;

  &::placeholder {
    color: #fff;
  }
  @media screen and (max-width: 756px) {
    width: 300px;
  }
}

.buttonContact {
  border-radius: 4px;
  background: none;
  white-space: nowrap;
  padding: 10px 20px;
  font-weight: 600;
  font-family: "Share Tech Mono", monospace;
  color: rgb(3, 233, 244);
  font-size: 1.1rem;
  outline: none;
  border: 2px solid #03e9f4;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  &:before {
    background: rgb(138, 43, 226, 0.5);
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    transition: all 0.6s ease;
    width: 102%;
    height: 0%;
  }

  &:hover:before {
    height: 200%;
  }
  &:hover {
    z-index: 1;
    color: #03e9f4;
  }
  @media screen and (max-width: 756px) {
    width: 300px;
  }
}
