.backgroundContainer {
  position: relative;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 120px);
  object-fit: cover;
  opacity: 0.05;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(3, 7, 26, 0.5);
}

.container {
  margin: 0;
  padding: 2rem 20%;
  box-sizing: border-box;
  position: relative;
  display: flex;
}

.pageTitle p {
  color: rgb(3, 233, 244);
  font-size: 2.2rem;
  font-family: "Share Tech Mono", monospace;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.text {
  font-family: "Share Tech Mono", monospace;
  font-size: 1.1rem;
  color: lightblue;
  margin-top: 0;
  margin-right: 5rem;
}

.avatarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 4.5rem;
}

.avatarContainer span {
  margin-top: 3rem;
}

.avatar {
  width: 350px;
  height: 350px;
  border-radius: 1rem;
  border: 2px solid rgb(138, 43, 226);
}

.btnCv {
  position: relative;
  width: 120px;
  height: 40px;
  background-color: rgb(4, 9, 32, 0.8);
  display: flex;
  align-items: center;
  color: rgb(138, 43, 226);
  flex-direction: column;
  justify-content: center;
  border: none;
  padding: 12px;
  gap: 12px;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Share Tech Mono", monospace;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
}

.btnCvLink {
  text-decoration: none;
}

.btnCv::before {
  content: "";
  position: absolute;
  inset: 0;
  left: -4px;
  top: -1px;
  margin: auto;
  width: 128px;
  height: 48px;
  border-radius: 10px;
  background: linear-gradient(-45deg, #e81cff 0%, #40c9ff 100%);
  z-index: -10;
  pointer-events: none;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  text-decoration: none;
}

.btnCv::after {
  content: "";
  z-index: -1;
  position: absolute;
  inset: inherit;
  background: linear-gradient(-45deg, #fc00ff 0%, #00dbde 100%);
  transform: translate3d(0, 0, 0) scale(0.95);
  filter: blur(20px);
  text-decoration: none;
}

.btnCv:hover::after {
  filter: blur(30px);
}

.btnCv:hover::before {
  transform: rotate(-180deg);
}

.btnCv:active::before {
  scale: 0.7;
}

.skillsContainer p {
  color: lightblue;
  font-size: 1rem;
  font-weight: 600;
}

.skills {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  max-width: 560px;
  cursor: grab;
}

.skills span {
  font-size: 0.8rem;
  padding: 0.4rem 0.3rem;
  background-color: transparent;
  border-radius: 5px;
}

.skillsSpan::before {
  content: "➤";
  color: rgb(3, 233, 244);
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 0.525rem;
}

.skillsSpan {
  padding: 12.5px 30px;
  border: 0;
  border-radius: 100px;
  background-color: rgb(3, 233, 244, 0.8);
  color: rgb(138, 43, 226);
  font-weight: bold;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.skillsSpan:hover {
  background-color: rgb(3, 233, 244, 0.8);
  box-shadow: 0 0 20px rgb(3, 233, 244, 0.3);
  transform: scale(1.1);
}

.skillsSpan:active {
  background-color: rgb(3, 233, 244);
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
  box-shadow: none;
  transform: scale(0.98);
}

/* RESPONSIVO */

@media screen and (max-width: 1200px) {
  .avatar {
    width: 250px;
    height: 250px;
  }
}

@media screen and (max-width: 992px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 5%;
  }

  .text {
    margin-left: 0.75rem;
    margin-top: 2rem;
    margin-right: 0px;
    margin-bottom: 4rem;
  }
  .avatar {
    margin-top: 3rem;
  }
  .avatarContainer {
    order: -1;
  }
}
@media screen and (max-width: 460px) {
  .skills {
    display: flex;
    flex-direction: column;
  }

  .avatarContainer {
    margin-top: 1rem;
  }
}
