.backgroundContainer {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 120px);
  object-fit: cover;
  opacity: 0.05;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(3, 7, 26, 0.5);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 0;
  padding: 2rem 20%;
  box-sizing: border-box;
  position: relative;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.welcomeContainer {
  display: flex;
  margin-bottom: 0.8rem;
  align-self: baseline;
}

.welcome {
  font-family: "Share Tech Mono", monospace;
  font-size: 2rem;
  font-weight: 100;
  color: rgb(178, 224, 224);
}

.name {
  display: flex;
  font-family: "Share Tech Mono", monospace;
  font-size: 7rem;
  font-weight: 600;
  color: rgb(138, 43, 226);
  position: relative;
  &:after {
    --deco-height: 0.3125em;
    max-width: 100%;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: calc(var(--deco-height) * -0.625);
    height: var(--deco-height);
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M-17 30.5C-1 22 72-4 54 13 37.9 28.2-2.5 57.5 16 55.5s72-29 104-40' stroke='%2300FDCF' stroke-width='10'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h100v64H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    background-size: auto 100%;
    background-repeat: round;
    background-position: 0em;
  }
}

.subtitle {
  display: flex;
  align-self: flex-end;
  font-family: "Share Tech Mono", monospace;
  font-size: 2.5rem;
  margin-top: 3rem;
  margin-left: auto;
}

.subtitle span {
  overflow: hidden;
  border-right: 2px solid #fff;
  white-space: nowrap;
  animation: typewriter 2s steps(44) 1s 1 normal both,
    blinkTextCursor 600ms infinite;
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: #fff;
  }
  to {
    border-right-color: transparent;
  }
}

@media screen and (max-width: 992px) {
  .welcome {
    margin-top: 2rem;
  }
  .subtitle {
    padding-right: 0;
  }
}

@media screen and (max-width: 530px) {
  .welcomeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .welcome {
    font-size: 1.7rem;
    margin-top: 0rem;
    margin-bottom: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .container {
    padding: 0 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .name {
    font-size: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .name::after{
    bottom: calc(var(--deco-height) * -1.25);
  }

  .subtitle {
    margin-top: 4rem;
    font-size: 2rem;
  }
}

@media screen and (max-width: 430px) {
  .name {
    font-size: 4.5rem;
  }
  .subtitle {
    font-size: 2rem;
  }
}
