footer {
  position: relative;
  padding-bottom: 1rem;
}

footer span {
  font-size: 0.75rem;
  color: rgb(69, 187, 226);
}

.footer {
  display: flex;
  width: 100%;
  height: 70px;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -20px 30px -32px magenta;
}

.footer img {
  width: 35px;
  height: 35px;
  margin: 0 0.5rem;
  -webkit-transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
}

.footer img:hover {
  -webkit-transform: scale(1.2) rotate(0.01deg);
  transform: scale(1.2) rotate(0.01deg);
}

.footerSpan {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  font-family: "Source Code Pro", monospace;
}
